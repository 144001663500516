import {mapState} from 'vuex'

export default {
    data(){
        return {
            bar_chart_settings: {
                light: {
                    bg_colors: ['#FFFFFF', '#F7F7F7'],
                    bg_splitter: '#DFE0EB',
                    label_color: '#20253B',
                    bar_bg_color: '#0079FA',
                    series_label_color: '#FFFFFF'
                },
                dark: {
                    bg_colors: ['#121212', '#161616'],
                    bg_splitter: '#2B2C37',
                    label_color: '#FFFFFF',
                    bar_bg_color: '#0079FA',
                    series_label_color: '#FFFFFF'
                }
            },
            line_chart_settings: {
                light: {
                    bg_colors: ['#FFFFFF', '#F7F7F7'],
                    bg_splitter: '#DFE0EB',
                    label_color: '#20253B',
                    line_color: '#0079FA',
                    axis_color: '#0079FA',
                },
                dark: {
                    bg_colors: ['#121212', '#161616'],
                    bg_splitter: '#2B2C37',
                    label_color: '#FFFFFF',
                    line_color: '#0079FA',
                    axis_color: '#0079FA',
                }
            },
            scatter_chart_settings: {
                light: {
                    bg_colors: ['#FFFFFF', '#F7F7F7'],
                    bg_splitter: '#DFE0EB',
                    label_color: '#20253B',
                    bubble_color: '#0079FA'
                },
                dark: {
                    bg_colors: ['#121212', '#161616'],
                    bg_splitter: '#2B2C37',
                    label_color: '#FFFFFF',
                    bubble_color: '#0079FA'
                }
            },
            regression_line_settings: {
                light: {
                    bg_colors: ['#FFFFFF', '#F7F7F7'],
                    bg_splitter: '#DFE0EB',
                    label_color: '#20253B',
                    line_color: '#1F9992'
                },
                dark: {
                    bg_colors: ['#121212', '#161616'],
                    bg_splitter: '#2B2C37',
                    label_color: '#FFFFFF',
                    line_color: '#1F9992'
                }
            }
        }
    },

    computed: {
        ...mapState({
            current_theme: state => state.themes.current_theme
        }),
        scatter_style() {
            return {
                itemStyle: {
                    color: this.scatter_chart_settings[this.current_theme].bubble_color
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: this.scatter_chart_settings[this.current_theme].bg_splitter,
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: this.scatter_chart_settings[this.current_theme].bg_colors
                    }
                },
                yAxis : {
                    axisLabel: {
                        color: this.bar_chart_settings[this.current_theme].label_color
                    }
                },
                xAxis : {
                    axisLabel: {
                        color: this.bar_chart_settings[this.current_theme].label_color
                    }
                }
            }
        },
        regression_style(){
            return {
                itemStyle: {
                    color: this.regression_line_settings[this.current_theme].line_color
                }
            }
        }
    }
}